import React, { useRef, useState } from "react";
import "./SLButtons.css";
import facebook from "../../../../../assets/images/SocialLoginButtonIcons/facebook.svg";
import google from "../../../../../assets/images/SocialLoginButtonIcons/google.svg";
import mobile from "../../../../../assets/images/SocialLoginButtonIcons/mobile.svg";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {
  SnackbarEvent,
  useEventDispatch,
} from "../../../../../Services/Events";
import { useHistory } from "react-router-dom";
import {
  FacebookAuthProvider,
  getAuth,
  GoogleAuthProvider,
  signInWithCredential,
  signInWithPopup,
} from "firebase/auth";
import { checkIfNewUser } from "../../../../../Services/Auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useGoogleLogin } from "@react-oauth/google";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: "border-box",
    width: "100%",
    maxWidth: 390,
    /* position: 'absolute',
    bottom: 20, */

    [theme.breakpoints.down("sm")]: {
      bottom: 99,
    },

    [theme.breakpoints.down("xs")]: {
      position: "relative",
      marginTop: 48,
      bottom: 0,
    },

    "& > .MuiButtonBase-root": {
      display: "block",
      margin: "16px 0px",
      maxWidth: 390,
      width: "100%",
      textTransform: "none",
      padding: 10,
    },

    "& .MuiButton-outlined": {
      border: "1px solid rgba(var(--theme-divider))",
      borderRadius: 4,
      height: 48,
    },
  },

  socialButton: {
    width: "fit-content",
    margin: "auto",
    display: "flex",
  },
}));

function SLButtons({
  redirect,
  signUp,
  setMobileSignup,
  setMobileLogin,
  postProcess,
  disableMobileLogin,
}) {
  const classes = useStyles();
  const dispatchSnackbar = useEventDispatch(SnackbarEvent);
  const auth = getAuth();
  const history = useHistory();

  const [googleSignInLoading, setGoogleSignInLoading] = useState(false);

  //use this for enterprise
  const recaptchaHandler = (response) => {
    window.grecaptcha.enterprise.ready(() => {
      googleSignupHandler(response);
    });
  };

  const googleSignupHandler = async ({ code, ...rest }) => {
    const recaptchaToken = await window.grecaptcha.enterprise.execute(
      process.env.REACT_APP_RECAPTCHA_ENTERPRISE_KEY,
      {
        action: "SIGN_IN",
      }
    );

    setGoogleSignInLoading(true);

    const response = await httpsCallable(
      getFunctions(),
      "googlesignin"
    )({
      token: recaptchaToken,
      code: code,
    });

    const data = response.data;

    if (!data.success) {
      setGoogleSignInLoading(false);
      handleError("Authorization failed : " + data.reason, true);
      return;
    }

    const credential = GoogleAuthProvider.credential(data.idToken);

    signInWithCredential(auth, credential)
      .then((res) => {
        // check scores
        setGoogleSignInLoading(false);
        SLpostProcess(res, GoogleAuthProvider.PROVIDER_ID);
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const googleLogin = useGoogleLogin({
    onSuccess: recaptchaHandler,
    flow: "auth-code",
    ux_mode: "popup",
    select_account: true,
    onNonOAuthError: (error) =>
      handleError("Login with Google failed : " + error.type, true),
    onError: (error) => handleError(error.error_description, true),
  });

  const SLpostProcess = async (res, provider) => {
    postProcess(
      await checkIfNewUser(
        res,
        {
          name: res.user.displayName,
          mail: res.user.email,
          provider: provider,
          imgURL: res.user.photoURL,
        },
        history,
        redirect
      ),
      res.user
    );
  };

  function handleError(error, showError = false) {
    if (error.code === "auth/account-exists-with-different-credential") {
      dispatchSnackbar({ msg: "Account already exist with email", open: true });
    } else if (!showError) {
      dispatchSnackbar({ msg: "Something went wrong.", open: true });
    } else {
      dispatchSnackbar({ msg: error, open: true });
    }
    console.error(error.message, error.code);
  }

  const facebookSignupHandler = () => {
    const provider = new FacebookAuthProvider();
    signInWithPopup(auth, provider)
      .then((res) => {
        SLpostProcess(res, FacebookAuthProvider.PROVIDER_ID);
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const handleMobile = (e) => {
    if (signUp) {
      setMobileSignup(true);
    } else {
      setMobileLogin(true);
    }
  };

  return (
    <div className={classes.root}>      
      <Button
        variant="outlined"
        disabled={googleSignInLoading}
        onClick={googleLogin}
      >
        <div className={classes.socialButton}>
          <div className="bLogo">
            <img src={google} alt="google" />
          </div>
          <div className="bTextGoogle">Continue with Google</div>
          {googleSignInLoading && (
            <CircularProgress color="secondary" size={24} />
          )}
        </div>
      </Button>

      <Button variant="outlined" onClick={facebookSignupHandler}>
        <div className={classes.socialButton}>
          <div className="bLogo">
            <img src={facebook} alt="facebook" />
          </div>
          <div className="bTextFacebook">Continue with Facebook</div>
        </div>
      </Button>

      {!disableMobileLogin && (
        <Button variant="outlined" onClick={handleMobile}>
          <div className={classes.socialButton}>
            <div className="bLogo">
              <img src={mobile} alt="mobile" />
            </div>
            <div>
              <span className="bTextMobile">Continue with mobile</span>
            </div>
          </div>
        </Button>
      )}
    </div>
  );
}

export default SLButtons;

import React from "react";
import "../Verification.css";
import { AuthDialogEvent, useEventDispatch } from "../../../Services/Events";
import { ReactComponent as Tick } from "../../../assets/icons/GreenTick.svg";
import { ReactComponent as Logo } from "../../../assets/icons/NesoColoredIcon.svg";
import { ReactComponent as FailIcon } from "../../../assets/icons/FailedIcon.svg";
import { Dialog } from "@material-ui/core";

const ForumVerification = ({ signedIn, email, error }) => {
  const dispatchAuth = useEventDispatch(AuthDialogEvent);

  function login() {
    dispatchAuth({
      auth: true,
      redirect: document.location.pathname + document.location.search,
      disableMobileAuth: true,
    });
  }

  return (
    <Dialog fullScreen open={true}>
      <div className="verification-container">
        {error ? <FailIcon /> : signedIn ? <Tick /> : <Logo />}
        {signedIn ? (
          <>
            <h6 className="verification-title">Neso Academy Forum</h6>
            <p className="verification-content">
              {error ? (
                error
              ) : (
                <span>
                  Your email <b>{email}</b> has been signed in successfully.
                  Redirecting to Forum.
                </span>
              )}
            </p>
          </>
        ) : (
          <>
            <h6 className="verification-title">Neso Academy Forum</h6>
            <p className="verification-content">Please log in to continue.</p>
            <button
              className="verification-btn"
              style={{ cursor: "pointer" }}
              onClick={login}
            >
              <h6 className="ctn">Login</h6>
            </button>
          </>
        )}
      </div>
    </Dialog>
  );
};

export default ForumVerification;

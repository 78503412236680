import { Alert } from "@material-ui/lab";
import { useDatabase } from "../../Services/Database";

const RecentPaymentStatus = ({ user }) => {
  const lastTransaction = useDatabase(
    user && `/Users/${user.uid}/lastTransaction`
  );

  if (!lastTransaction.data) {
    return <div></div>;
  }

  const status = lastTransaction.data["status"];
  const timestamp = lastTransaction.data["timestamp"];

  if (timestamp + 3600000 < Date.now()) {
    return <div></div>;
  }

  const severity = status === "failed" ? "info" : "warning";

  const statement =
    status === "failed" ? (
      <span>
        Your previous transaction could not be completed due to the following
        reason: <br /> "{lastTransaction.data["errorReason"] || "Payment failed"}"
      </span>
    ) : status === "pending" ? (
      <span>
        Your previous transaction is still pending. Please verify before
        continuing. If this is a mistake{" "}
        <a target="_blank" href="/support">
          contact us
        </a>
      </span>
    ) : (
      <span>
        Your previous transaction was successful. Please verify before
        continuing. If this is a mistake{" "}
        <a target="_blank" href="/support">
          contact us
        </a>
      </span>
    );

  return (
    <Alert className="recent-payment-status" severity={severity}>
      {statement}
    </Alert>
  );
};

export default RecentPaymentStatus;

/* import 'react-app-polyfill/stable'; */ //internet explorer support removed :)
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import FirebaseProvider from "./components/HigherOrderComponents/FirebaseProvider";
import { GoogleOAuthProvider } from "@react-oauth/google";

ReactDOM.render(
  <FirebaseProvider>
    <GoogleOAuthProvider clientId="708140357806-d6sfq0m1g8gsagq446qrno0d9jd7mddo.apps.googleusercontent.com">
      <App />
    </GoogleOAuthProvider>
  </FirebaseProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

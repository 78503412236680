import React, { useEffect, useState } from "react";

import { getFunctions, httpsCallable } from "firebase/functions";
import ForumVerification from "../../components/UserManagement/ForumSignIn/ForumVerification";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import { useUser } from "../../Services/Auth";

const ForumSignin = () => {
  const user = useUser();

  const [error, setError] = useState(null);

  useEffect(() => {
    if (user?.email) {
      redirectToDiscourse();
    }
  }, [user?.email]);

  async function redirectToDiscourse() {
    const params = new URLSearchParams(document.location.search);
    const discoursePayload = params.get("sso");
    const discourseSignature = params.get("sig");
    try {
      const response = await httpsCallable(
        getFunctions(),
        "discoursesso"
      )({ discoursePayload, discourseSignature });

      window.location.replace(response.data.redirectUrl);
    } catch (error) {
      console.error(error);
      setError(
        "Authentication has been failed. Kindly close this tab and try again."
      );
    }
  }

  return (
    <ForumVerification
      signedIn={user !== null}
      email={user?.email}
      error={error}
    />
  );
};

export default ForumSignin;
